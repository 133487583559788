<template>
    <div class="loader-wrapper" :style="estilo">
        <div class="loader is-loading" :style="estiloLoader"></div>
    </div>
</template>

<script>
    export default {
        name: "Loader",
        props: ['altura', 'escala'],
        computed: {
            estilo() {
                return {
                    height: `${this.altura ?? 80}vh`
                }
            },
            estiloLoader() {
                const iEscala = this.escala ?? 1

                return {
                    height: `${4 / iEscala}em`,
                    width: `${4 / iEscala}em`,
                    border: `${4 / iEscala}px solid #3273dc`
                }
            }
        },
    }
</script>

<style>
    .loader-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .loader-wrapper .loader{
        border-right-color: transparent!important;
        border-top-color: transparent!important;
        -webkit-animation: spinAround .5s infinite linear;
        animation: spinAround .5s infinite linear;
    }
</style>