<template>
    <div class="mensagem" :class="{verde: tipo, reduzirPadding: idArquivo != ''}">
        <div v-if="idArquivo == ''">    
            {{mensagem}}
        </div>
        <div v-else class="arquivo" :class="{verde: tipo}" @click="baixarArquivo(idArquivo)">
            <div class="icone">
                <i class="gg-file-document"></i>
            </div>
            <div class="nomeArquivo">
                {{nomeArquivo}}
            </div>
            <div class="baixar">
                <i class="gg-software-download"></i>
            </div>
        </div>
        <span>{{dataMensagem}}</span>
    </div>
</template>

<script>
    export default {
        name: "MensagemDaConversaAtiva",
        props: ['mensagem', 'dataMensagem', 'tipo', 'nomeArquivo', 'idArquivo'],
        methods: {
            baixarArquivo(id) {
                window.open(`http://localhost:3001/v1/whatsapp/download/${id.toString()}`, '_parent')
            }
        },
    };
</script>

<style>
    .mensagem{
        font-size: 14px;
        box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px 0px;;
        background: white;
        margin: 5px 100px;
        padding: 5px 10px;
        border-radius: 5px;
        width: fit-content;
        max-width: 60%;
    }

    .mensagem.reduzirPadding{
        padding: 5px;
    }

    .mensagem.verde{
        background: #d9fdd3;
        align-self:  flex-end;
    }

    .mensagem span{
        margin-left: 25px;
        font-size: 10px;
        float: right;
        margin-top: 6px;
        color: gray;
    }

    .arquivo{
        background: #f5f6f6;
        display: flex;
        cursor: pointer;
    }

    .arquivo.verde{
        background: #d1f4cc!important;
    }

    .arquivo .icone{
        padding: 13px;
    }

    .arquivo .nomeArquivo{
        padding: 10px 0px;
    }

    .arquivo .baixar{
        padding: 15px 25px;
    }
</style>