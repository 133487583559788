<template>
    <div>
        <Loader v-if="carregando" />
        <div v-show="autenticacao.autenticado && !carregando">
            <section class="section">
                <div class="container">
                    <div class="columns janela-principal">
                        <div class="column is-3 lista-de-conversas">
                            <div class="barra-superior">

                                <Button
                                    :classes="`is-link is-outlined`" 
                                    :icone="`add`" 
                                    :titulo="`Novo contato`" 
                                    :onclick="addNovoContato"
                                />

                                <div class="dropdown is-right is-hoverable icon-config">
                                    <div class="dropdown-trigger">
                                        <i class="gg-more-o"></i>
                                    </div>
                                    <div class="dropdown-menu" id="dropdown-menu" role="menu">
                                        <div class="dropdown-content">
                                            <a href="/usuarios" v-if="autenticacao.admin == true" class="dropdown-item">
                                                Gerenciador de usuários
                                            </a>
                                            <a href="/modelos" v-if="autenticacao.admin == true" class="dropdown-item">
                                                Gerenciador de modelos
                                            </a>
                                            <a href="/cliente" v-if="autenticacao.admin == true" class="dropdown-item">
                                                Gerenciador do cliente
                                            </a>
                                            <a href="" class="dropdown-item" @click="desconectar">
                                                Desconectar
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Loader v-if="carregandoContatos" />

                            <div class="barra-superior barra-superior-filtro"  v-show="!carregandoContatos">

                                <InputFiltro
                                    :onkeyup="listarContatos" 
                                    :placeholder="`Filtrar por nome ou número`" 
                                    v-model="filtro"
                                />

                            </div>
                            
                            <div v-if="totalRegistrosArquivados > 0"  v-show="!carregandoContatos">

                                <Button
                                    v-if="arquivados == false"
                                    :classes="`is-link is-outlined btn-visualizar-arquivados`" 
                                    :icone="`folder`" 
                                    :titulo="`Visualizar arquivadas`" 
                                    :onclick="exibirConversasArquivadas"
                                    :parametro1="true"
                                />

                                <Button
                                    v-else
                                    :classes="`is-info is-outlined btn-visualizar-arquivados`" 
                                    :icone="`chevron-left`" 
                                    :titulo="`Voltar para as conversas ativas`" 
                                    :onclick="exibirConversasArquivadas"
                                    :parametro1="false"
                                />

                            </div>

                            <div class="contatos" v-show="!carregandoContatos">

                                <Contatos 
                                    v-for="(contato, idxContato) in contatos"
                                    :key="idxContato" 
                                    :mensagem-ativa="contato.numero == indiceAtivo"
                                    :nome="contato.nome"
                                    :numero="contato.numero"
                                    :nao-lidas="contato.quantidadeNaoLidas"
                                    :ultima-mensagem="contato.ultimaMensagem"
                                    :data-mensagem="contato.dataUltimaMensagem"
                                    :arquivado="contato.arquivado"
                                    :arquivar-conversa="arquivarConversa"
                                    @click.native="verificarClique($event); cancelarTemplate(); listarMensagens(contato.numero)"
                                />
                            </div>
                        </div>
                        <div class="column conversa-ativa">
                            <div class="barra-superior">
                                <span>{{nomeConversa}}</span>
                            </div>
                            <div class="mensagens" :class="{ 'novoContato': novoContato }">

                                <NovaMensagemTemplate 
                                    v-if="novoContato"
                                    :nome="nomeTemplate"
                                    :numero="numeroTemplate"
                                    :enviar-mensagem="enviarMensagem"
                                    :autenticacao="autenticacao"
                                    :cancelar-template="cancelarTemplate"
                                />

                                
                                    
                                <Loader v-if="carregandoMensagem" />

                                <div class="lista-mensagens">
                                    
                                    <Loader 
                                        v-if="carregandoNovasMensagens" 
                                        :altura="10"
                                        :escala="2"
                                    />

                                    <MensagemDaConversaAtiva
                                        v-if="!novoContato && !carregandoMensagem"
                                        v-for="(mensagem, idxMensagem) in mensagens"
                                        :mensagem="mensagem.mensagem"
                                        :nomeArquivo="mensagem.nomeArquivo"
                                        :idArquivo="mensagem.idArquivo"
                                        :dataMensagem="mensagem.dataMensagem"
                                        :tipo="mensagem.tipo == 'IN' ? true : false"
                                        :key="idxMensagem" 
                                    />

                                </div>
                            </div>
                            <div v-show="nomeConversa != '' && !carregandoMensagem">
                                <input id="arquivo" name="arquivo" type="file" hidden>
                                <div class="barra-inferior" v-if="!novaMensagemTemplate">
                                    <Button
                                        :classes="`is-white btn-acoes`" 
                                        :icone="`file-add`" 
                                        :onclick="enviarArquivo"
                                    />

                                    <input type="text" class="input" @keyup.enter.exact="enviarMensagem" placeholder="Insira sua mensagem" v-model="conteudoMensagem">
                                    
                                    <Button
                                        :classes="`is-success btn-acoes`" 
                                        :icone="`chevron-double-right`" 
                                        :onclick="enviarMensagem"
                                    />

                                    <Button
                                        :classes="`is-link btn-acoes`" 
                                        :icone="`template`" 
                                        :onclick="enviarMensagemTemplate"
                                    />

                                </div>
                                <div class="barra-inferior barra-inferior-template" v-else-if="!novoContato">
                                    <input type="button" class="input inp-btn" @click="enviarMensagemTemplate" value="Enviar mensagem com modelo">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import moment from "moment"
    import { Buffer } from 'buffer'
    import Contatos from './Contatos.vue'
    import MensagemDaConversaAtiva from './MensagemDaConversaAtiva.vue'
    import NovaMensagemTemplate from './NovaMensagemTemplate.vue'

    export default {
        name: "Mensagens",
        components: {
            Contatos,
            MensagemDaConversaAtiva,
            NovaMensagemTemplate
        },
        data(){
            return {
                contatos: [
                    {
                        "nome": "",
                        "numero": "",
                        "ultimaMensagem": "",
                        "dataUltimaMensagem": "",
                        "tipoUltimaMensagem": "",
                        "quantidadeNaoLidas": 0
                    }
                ],
                contatosIdentificados: [],
                mensagens: [],
                indiceAtivo: sessionStorage.getItem('indice'),
                conteudoMensagem: '',
                novoContato: false,
                novaMensagemTemplate: true,
                nomeConversa: '',
                numeroTemplate: '',
                nomeTemplate: '',
                timer: null,
                scrollTopoConversa: false,
                scrollFinalConversa: true,
                totalRegistros: 0,
                totalRegistrosArquivados: 0,
                paginacao: 1,
                filtro: '',
                arquivando: false,
                carregando: true,
                carregandoContatos: false,
                carregandoMensagem: true,
                carregandoNovasMensagens: false,
                arquivados: false,
                autenticacao: {
                    autenticado1: false,
                    autenticado: false,
                    admin: false,
                    id_token: '',
                    access_token: ''
                }
            }
        },
        async created(){
            /**
             * Realizar a autenticação
             */
            await this.autenticar()

            /**
             * Verifica se esta autenticado
             */
            if(this.autenticacao.autenticado){
                /**
                 * Lista os contatos
                 */
                await this.listarContatos()

                /**
                 * Se existir contato selecionado, lista as mensagens
                 */
                if(this.contatosIdentificados[this.indiceAtivo]){
                    this.carregandoMensagem = true

                    await this.listarMensagens(this.contatosIdentificados[this.indiceAtivo].numero)
                }
            }  
        },
        async mounted() {
            /**
             * Realizar a autenticação
             */
            await this.autenticar()

            /**
             * Verifica se esta autenticado
             */
            if(this.autenticacao.autenticado){
                /**
                 * Inicia o timer para atualizar os dados
                 */
                this.startTimer()

                /**
                 * Captura o scroll na div
                 * 
                 * @var {object} oScrollDiv
                 */
                const oScrollDiv = document.querySelector('.mensagens')

                /**
                 * Adiciona o evento no scroll
                 */
                oScrollDiv.addEventListener('scroll', this.handleScroll)
            }

            if(this.nomeConversa == ''){
                this.carregandoMensagem = false
            }
        },
        beforeDestroy() {
            /**
             * Limpa o timer de atualização dos dados
             */
            clearInterval(this.timer)
            
            /**
             * Remove o evento do scroll
             */
            window.removeEventListener('scroll', this.handleScroll)
        },
        methods: {
            /**
             * Função para verificar o local que esta sendo clicado
             */
            verificarClique(event){
                /**
                 * Define as classes que devem ser verificadas
                 * 
                 * @var {array} aClasses
                 */
                const aClasses = [
                    'dropdown-item',
                    'dropdown-content',
                    'gg-chevron-down'
                ]

                if(aClasses.includes(event.target.className)){
                    this.arquivando = true
                }else{
                    this.arquivando = false
                }
            },
            /**
             * Função para realizar a autenticação da aplicação
             */
            autenticar(){
                /**
                 * Busca os parametros da URL
                 *
                 * @var {object} oParams
                 */
                const oParams = new URLSearchParams(window.location.hash)

                /**
                 * Limpa os dados do URL
                 */
                window.location.hash = ''
                
                /**
                 * Verifica se existe o token de autenticação
                 */
                if(!oParams.get('access_token') && !localStorage.getItem('access_token')){
                    /**
                     * Redireciona para a autenticação caso não exista
                     */
                    window.location.href = process.env.VUE_APP_ENDPOINT_AUTH

                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.autenticado = false

                    return false
                }

                if(oParams.get('access_token')){
                    localStorage.setItem('id_token', oParams.get('#id_token'))
                    localStorage.setItem('access_token', oParams.get('access_token'))
                    localStorage.setItem('token_type', oParams.get('token_type'))
                }

                /**
                 * Define os dados nas variaveis
                 */
                this.autenticacao.autenticado = true
                this.autenticacao.id_token = oParams.get('#id_token') ?? localStorage.getItem('id_token')
                this.autenticacao.access_token = oParams.get('access_token') ?? localStorage.getItem('access_token')
                this.autenticacao.token_type = oParams.get('token_type') ?? localStorage.getItem('token_type')

                /**
                 * Transforma o token JWT em um array, quebrando pelo caractere ponto (.)
                 *
                 * @var {string} sHeader
                 * @var {string} sPayload
                 * @var {string} sSignature
                 */
                const [sHeader, sPayload, sSignature] = this.autenticacao.access_token
                    .toString()
                    .split('.')

                /**
                 * Transforma o dado em base64 para string legível
                 *
                 * @var {Object} oDadosDescriptografados
                 */
                const oDadosDescriptografados = JSON.parse(
                    (Buffer.from(sPayload, 'base64'))
                    .toString('ascii')
                )

                /**
                 * Verifica se o usuario é admin
                 */
                if(oDadosDescriptografados['cognito:groups'].includes('Administradores')){
                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.admin = true
                }

                /**
                 * Verifica se o token não está expirado
                 */
                if(moment().isAfter(moment.unix(oDadosDescriptografados.exp))){
                    /**
                     * Redireciona para a autenticação
                     */
                    window.location.href = process.env.VUE_APP_ENDPOINT_AUTH

                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.autenticado = false

                    return false
                }

                /**
                 * Remove o # qque é informado na URL de retorno da AWS
                 */
                if (window.location.href.charAt(window.location.href.length - 1) == "#") {
                    window.location.replace(window.location.href.split("#")[0]);
                }

                return true
            },
            /**
             * Função para verificar o scroll na div
             * 
             * @var {event} event
             */
            async handleScroll(event){
                if(this.carregandoMensagem){
                    return
                }

                /**
                 * Define a div que foi realizado o scroll
                 * 
                 * @var {object} oScrollDiv
                 */
                const oScrollDiv = event.target

                /**
                 * Verifica se a div foi scrollada até o final
                 */
                this.scrollFinalConversa = oScrollDiv.scrollTop >= oScrollDiv.scrollHeight - oScrollDiv.clientHeight

                /**
                 * Verifica se a div foi scrollada até o inicio
                 */
                if(oScrollDiv.scrollTop == 0 && this.contatosIdentificados[this.indiceAtivo]){
                    /**
                     * Verifica se a existe registros para carregar
                     */
                    if((this.paginacao*100) <= this.totalRegistros){
                        /**
                         * Adiciona a paginação
                         */
                        this.paginacao++

                        /**
                         * Adiciona o loader
                         */
                        this.carregandoNovasMensagens = true

                        /**
                         * Lista as mensagens
                         */
                        await this.listarMensagens(this.contatosIdentificados[this.indiceAtivo].numero)     
                        
                        /**
                         * Define a posição do scroll para continuar de onde foi feito
                         * a requisição de novas mensagens
                         */
                        oScrollDiv.scrollTop = oScrollDiv.scrollHeight/this.paginacao

                        /**
                         * Remove o loader
                         */
                         this.carregandoNovasMensagens = false
                    }
                }
            },
            /**
             * Função para realizar a atualização dos dados
             */
            async startTimer(){
                /**
                 * Faz a a requisição a cada 1 segundo
                 */
                this.timer = setInterval(async () => {
                    /**
                     * Atualiza a lista de contatos
                     */
                    await this.listarContatos()

                    /**
                     * Verifica se existe um contato selecionado e se houve mensagens não lidas
                     */
                    if(this.contatosIdentificados[this.indiceAtivo] && this.contatosIdentificados[this.indiceAtivo].quantidadeNaoLidas > 0){
                        /**
                         * Atualiza a lista de mensagens
                         */
                        await this.listarMensagens(this.contatosIdentificados[this.indiceAtivo].numero)
                    }
                }, 3000)
            },
            /**
             * Função para buscar os contatos
             */
            async listarContatos(){
                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'GET',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Define o this para o that para poder usar dentro da função
                 * 
                 * @var {object} that
                 */
                const that = this

                /**
                 * Inicia o array dos contato que serão identificados pelo numero
                 * 
                 * @var {array} aContatosIdentificados
                 */
                let aContatosIdentificados = []

                /**
                 * Realiza a requisição para buscar os contatos
                 * 
                 * @var {array} aContatos
                 */
                var aContatos = await fetch(`${process.env.VUE_APP_ENDPOINT_API}/v1/whatsapp/contatos?pagina=1&registrosPorPagina=50&arquivado=${this.arquivados}`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        /**
                         * Define o total de registro arquivados
                         */
                        that.totalRegistrosArquivados = response.totalRegistrosArquivados
                        
                        /**
                         * Ordena os contatos com base na data da última mensagem
                         */
                        response.data.sort((a, b) => {
                            return new Date(b.dataUltimaMensagem) - new Date(a.dataUltimaMensagem)
                        })

                        /**
                         * Itera os contatos
                         */
                        response.data.map((aContato) => {
                            /**
                             * Formata a data da última mensagem
                             */
                            aContato.dataUltimaMensagem = moment(aContato.dataUltimaMensagem).format('DD/MM/YYYY HH:mm')

                            /**
                             * Adiciona o contato no novo array com o indice sendo
                             * o número do telefone
                             */
                            aContatosIdentificados[aContato.numero] = aContato

                            return aContato
                        })

                        return response.data
                    })
                    .catch(error => {
                        console.error(error)
                    })  

                aContatos = this.filtrarContatos(aContatos)

                if(this.totalRegistrosArquivados == 0 && this.arquivados){
                    this.exibirConversasArquivadas(false)
                }

                /**
                 * Remove o loader
                 */
                this.carregando = false

                /**
                 * Define os contato no objeto do vue
                 */
                this.contatos = aContatos
                this.contatosIdentificados = aContatosIdentificados
            },
            /**
             * Função para buscar as mensagens
             * 
             * @param {int} iNumero
             */
            async listarMensagens(iNumero, bForcarListagem = false){
                /**
                 * Verifica se o envio de mensagem via template está ativo
                 * ou se a operação é de arquivamento da conversa
                 */
                if((this.novoContato || this.arquivando == true) && !bForcarListagem){
                    return
                }

                /**
                 * Verifica se foi realizado a troca de contato
                 */
                if(this.indiceAtivo != iNumero){
                    this.indiceAtivo = iNumero 
                    sessionStorage.setItem('indice', iNumero) 
                    document.querySelector('.lista-mensagens').style.display = 'none'
                    this.novaMensagemTemplate = false
                    this.totalRegistros = 0
                    this.paginacao = 1
                    this.nomeConversa = ''
                    this.carregandoMensagem = true
                    this.scrollFinalConversa = true
                }

                /**
                 * Lista os contatos
                 */
                this.listarContatos()

                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'GET',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Inicia a variável que vai guardar a data da última mensagem recebida
                 * 
                 * @var {string} sDataUltimaMensagemRecebida
                 */
                let sDataUltimaMensagemRecebida = ''

                /**
                 * Define a quantidade de registros a ser buscado
                 * 
                 * @var {int} iRegistrosPorPagina
                 */
                const iRegistrosPorPagina = this.paginacao*100

                /**
                 * Realiza a requisição para buscar as mensagens
                 * 
                 * @var {array} aMensagens
                 */
                const aMensagens = await fetch(`${process.env.VUE_APP_ENDPOINT_API}/v3/whatsapp/historicoMensagens/contato/${iNumero}?registrosPorPagina=${iRegistrosPorPagina}`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        /**
                         * Ordena as mensagens com base na data da mensagem
                         */
                        response.data.sort((a, b) => {
                            return new Date(a.dataMensagem) - new Date(b.dataMensagem)
                        })

                        /**
                         * Itera as mensagens
                         */
                        response.data.map((aMensagem) => {
                            /**
                             * Se for mensagem recebida, guarda a data da mensagem
                             */
                            if(aMensagem.tipo == 'OUT'){
                                sDataUltimaMensagemRecebida = aMensagem.dataMensagem
                            }

                            /**
                             * Formata a data da mensagem
                             */
                            aMensagem.dataMensagem = moment(aMensagem.dataMensagem).format('DD/MM/YYYY HH:mm')

                            return aMensagem
                        })

                        return response.data
                    })
                    .catch(error => {
                        console.error(error)
                    })  

                /**
                 * Define o total de mensagens e as mensagen no objeto do vue
                 */
                this.totalRegistros = aMensagens.length
                this.mensagens = aMensagens
                
                /**
                 * Define o nome da conversa
                 */
                this.nomeConversa = this.contatosIdentificados[iNumero].nome ?? this.contatosIdentificados[iNumero].numero ?? ''

                /**
                 * Verifica qual o tipo de mensagem está disponível
                 */
                this.tipoMensagem(sDataUltimaMensagemRecebida, bForcarListagem)

                if(!bForcarListagem){
                    /**
                     * Remove o loader
                    */
                    this.carregandoMensagem = false
                }
                
                /**
                 * Aguarda o componente ser renderizado
                 */
                await this.$nextTick(() => {
                    /**
                     * Define a div como display flex
                     */
                    document.querySelector('.lista-mensagens').style.display = 'flex'

                    /**
                     * Verifica se o scroll está no final da conversa
                     */
                    if(this.scrollFinalConversa && document.querySelector('.mensagem:last-of-type')){
                        /**
                         * Realiza o scroll automático para o final da conversa
                         */
                        document.querySelector('.mensagem:last-of-type').scrollIntoView(false)
                    }
                })
            },
            /**
             * Função para realizar o envio das mensagens
             * 
             * @param {object} oMensagem
             */
            async enviarMensagem(oMensagem){     
                /**
                 * Define a mensagem no formato de texto
                 * 
                 * @param {object} oNovaMensagem
                 */
                let oNovaMensagem = {
                    "numeroDestinatario": this.contatosIdentificados[this.indiceAtivo]?.numero ?? '',
                    "tipo": "texto",
                    "parametros": {
                        "conteudo": this.conteudoMensagem
                    }
                }

                /**
                 * Verifica se a mensagem foi enviada via template
                 */
                if(oMensagem?.numeroDestinatario){
                    /**
                     * Substitui a mensagem pela do template
                     */
                    oNovaMensagem = oMensagem
                }else{
                    /**
                     * Verifica se o conteúdo da mensagem foi informado
                     */
                    if(this.conteudoMensagem == ''){
                        return 
                    } 
                }

                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define o corpo da requisição
                 * 
                 * @var {object} oHeaders
                 */
                let oBody = {}

                if(oNovaMensagem?.tipo && oNovaMensagem?.tipo == 'arquivo'){
                    oBody = new FormData()

                    oBody.append('arquivo', oNovaMensagem.arquivo)
                    oBody.append('numeroDestinatario', oNovaMensagem.numeroDestinatario)
                    oBody.append('tipo', oNovaMensagem.tipo)

                }else{
                    oHeaders.append("Content-Type", "application/json")

                    oBody = JSON.stringify(oNovaMensagem)
                }

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'POST',
                    headers: oHeaders,
                    body: oBody,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Verifica se a mensagem foi enviado via texto padrão
                 */
                if(this.conteudoMensagem != ''){
                    /**
                     * Adiciona a nova mensagem manualmente no objeto do vue
                     */
                    this.mensagens.push({
                        "tipo": "IN",
                        "status": "",
                        "dataMensagem": moment().format('DD/MM/YYYY HH:mm'),
                        "mensagem": this.conteudoMensagem,
                        "idArquivo": "",
                        "nomeArquivo": ""
                    })
                }     

                /**
                 * Remove o conteúdo do objeto do vue
                 */
                this.conteudoMensagem = ''          

                /**
                 * Aguarda o componente do vue ser renderizado
                 */
                this.$nextTick(() => {
                    if(document.querySelector('.mensagem:last-of-type')){        
                        /**
                         * Realiza o scroll automático para o final da conversa
                         */
                        document.querySelector('.mensagem:last-of-type').scrollIntoView(false)
                    }
                })

                /**
                 * Define se o template esta aberto
                 * 
                 * @var {boolean} bTemplate
                 */
                const bTemplate = this.novoContato

                /**
                 * Define o this para o that para poder usar dentro da função
                 * 
                 * @var {object} that
                 */
                 const that = this

                /**
                 * Realiza a requisição para enviar a mensagem
                 */
                await fetch(`${process.env.VUE_APP_ENDPOINT_API}/v3/whatsapp/enviarMensagem`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(async function(response) {
                        if(!response){
                            /**
                             * Remove o loading do botão
                             */
                            document.querySelector('.btn-control button').classList.remove('is-loading')

                            
                            return false
                        }

                        /**
                         * Verifica se teve sucesso no envio
                         */
                        if(response.conteudo){
                            
                            /**
                             * Lista os contato
                             */
                            await that.listarContatos()
                            
                            /**
                             * Define o indice do primeiro contato
                             */
                            that.indiceAtivo = that.contatos[0].numero
                            
                            /**
                             * Lista as mensagens
                             */
                            await that.listarMensagens(that.contatosIdentificados[that.indiceAtivo].numero, true)
                            
                            if(bTemplate){       
                                /**
                                 * Remove a nova mensagem via template
                                 */
                                that.novoContato = false
                            }

                            /**
                             * Aguarda o componente do vue ser renderizado
                             */
                            that.$nextTick(() => {
                                if(document.querySelector('.mensagem:last-of-type')){        
                                    /**
                                     * Realiza o scroll automático para o final da conversa
                                     */
                                    document.querySelector('.mensagem:last-of-type').scrollIntoView(false)
                                }
                            })
                        }             
                        
                        return response
                    })
                    .catch(error => {
                        console.error(error)
                    })  

                this.novoContato = false
                this.novaMensagemTemplate = sessionStorage.getItem('novaMensagemTemplate') == 'true'
            },
            /**
             * Função para verificar qual o tipo da mensagem é permitido enviar
             * 
             * @param {string} sData
             */
            tipoMensagem(sData, bForcarListagem = false){
                /**
                 * Verifica se esta ativo o envio por template
                 */
                if(this.novoContato || bForcarListagem){
                    return
                }

                /**
                 * Verifica se a data da última mensagem recebida está dentro
                 * das últimas 24 horas
                 */
                if(moment().isBefore(moment(sData).add(1, 'day')) && sData != ''){
                    /**
                     * Desativa a mensagem via template
                     */
                    this.novaMensagemTemplate = false
                    sessionStorage.setItem('novaMensagemTemplate', false)
                }else{
                    /**
                     * Ativa a mensagem via template
                     */
                    this.novaMensagemTemplate = true
                    sessionStorage.setItem('novaMensagemTemplate', true)
                }
            },
            /**
             * Função para exibir os dados do envio de mensagem via template
             */
            enviarMensagemTemplate() {
                this.novoContato = true
                this.novaMensagemTemplate = true
                this.numeroTemplate = this.contatosIdentificados[this.indiceAtivo].numero
                this.nomeTemplate = this.contatosIdentificados[this.indiceAtivo].nome
            },
            /**
             * Função para exibir os dados do novo contato
             */
            addNovoContato(){
                this.indiceAtivo = -1
                this.nomeConversa = "Novo contato",
                this.numeroTemplate = ''
                this.nomeTemplate = ''
                this.novoContato = true
                this.novaMensagemTemplate = true
            },
            /**
             * Função para cancelar o envio de mensagem via template
             */
            cancelarTemplate(){
                /**
                 * Verifica se a operação é de arquivamento da conversa
                 */
                if(this.arquivando == true){
                    return
                }

                this.novoContato = false
                this.novaMensagemTemplate = sessionStorage.getItem('novaMensagemTemplate') == 'true'
                this.indiceAtivo = sessionStorage.getItem('indice')
                this.nomeConversa = this.contatosIdentificados[this.indiceAtivo]?.nome ?? this.contatosIdentificados[this.indiceAtivo]?.numero ?? ''

                /**
                 * Aguarda o componente do vue ser renderizado
                 */
                this.$nextTick(() => {
                    if(document.querySelector('.mensagem:last-of-type')){        
                        /**
                         * Realiza o scroll automático para o final da conversa
                         */
                        document.querySelector('.mensagem:last-of-type').scrollIntoView(false)
                    }
                })
            },
            /**
             * Função para filtrar os contatos
             */
            filtrarContatos(aContatos){
                /**
                 * Verifica se existe informação no filtro
                 */
                if(this.filtro != ''){
                    /**
                     * Itera os contato com o filter e verifica 
                     * se existe nome ou numero compativel
                     */
                    return aContatos.filter(oContato => {
                        return oContato.nome.toLowerCase().includes(this.filtro.toLowerCase()) ||
                            oContato.numero.toString().includes(this.filtro)
                    })
                }

                /**
                 * Retorna os contatos caso não for realizado o filtro
                 */
                return aContatos
            },
            /**
             * Função para exibir ou não as conversas arquivadas
             */
            async exibirConversasArquivadas(bExibicao){
                this.arquivados = bExibicao
                this.indiceAtivo = 1
                this.mensagens = []
                this.nomeConversa = ''
                this.carregandoContatos = true
                
                /**
                 * Lista os contatos
                 */
                await this.listarContatos()

                this.carregandoContatos = false
            },
            /**
             * Função para arquivar a conversa
             * 
             * @param {string} sNumero
             * @param {boolean} bTipo
             */
            async arquivarConversa(sNumero, bTipo){   
                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'PUT',
                    headers: oHeaders,
                    body: JSON.stringify({arquivado: !bTipo}),
                    mode: 'cors',
                    redirect: 'follow'
                }    

                /**
                 * Define o this para o that para poder usar dentro da função
                 * 
                 * @var {object} that
                 */
                const that = this

                /**
                 * Realiza a requisição para enviar a mensagem
                 */
                await fetch(`${process.env.VUE_APP_ENDPOINT_API}/v1/whatsapp/contatos/${sNumero}`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }

                        return response.json()
                    })
                    .then(async function(response) {
                        /**
                         * Verifica se teve sucesso no envio
                         */
                        if(response.id){
                            /**
                             * Lista os contatos
                             */
                            await that.listarContatos()
                            
                            toastAlert(`Conversa ${bTipo == true ? 'desarquivada' : 'arquivada'}`, 'success')
                        }             
                        
                        return response
                    }) 
                    .catch(error => {
                        console.error(error)
                    })  
            },
            enviarArquivo(){
                document.getElementById("arquivo").click()
                
                /**
                 * Define o this para o that para poder usar dentro da função
                 * 
                 * @var {object} that
                 */
                const that = this

                document.getElementById("arquivo").onchange = async function(event) {
                    /**
                     * Define os dados
                     * 
                     * @param {object} oDados
                     */
                    const oDados = {
                        arquivo: event.target.files[0],
                        tipo: 'arquivo',
                        numeroDestinatario: that.contatosIdentificados[that.indiceAtivo]?.numero
                    }

                    that.carregandoMensagem = true

                    /**
                     * Envia o arquivo
                     */
                    await that.enviarMensagem(oDados)

                    /**
                     * Lista as mensagens
                     */
                    await that.listarMensagens(that.contatosIdentificados[that.indiceAtivo].numero, false)

                    /**
                     * Aguarda o componente do vue ser renderizado
                     */
                        that.$nextTick(() => {
                        if(document.querySelector('.mensagem:last-of-type')){        
                            /**
                             * Realiza o scroll automático para o final da conversa
                             */
                            document.querySelector('.mensagem:last-of-type').scrollIntoView(false)
                        }
                    })
                }
            },
            /**
             * Função para desconectar o usuario
             */
            desconectar(){
                this.autenticacao.autenticado = false
                localStorage.clear()       
                      
                /**
                 * Realizar a autenticação
                 */
                this.autenticar()
            },
        }
    }
</script>

<style>
    html{
        overflow: hidden;
    }

    .title:not(:last-child), .subtitle:not(:last-child){
        margin-bottom: 0.5em;
    }

    .inp-btn{
        color: #bbb
    }

    .janela-principal{
        height: 100vh;
        overflow: hidden;
    }

    .contatos{
        height: 89vh;
        overflow-y: auto;
    }

    .barra-superior{
        margin: 0;
        height: 50px;
        background: #EDEDED;
        border-right: 1px solid #E1E1E1;
        border-bottom: 1px solid rgb(200, 200, 200);
    }

    .barra-superior .gg-search{
        position: absolute;
        margin: 9px 27px;
        transform: scale(var(--ggs,0.6));
    }

    .barra-superior input{
        width: 100%;
        border: none;
        margin: 0;
        height: 100%;
        outline: none;
        padding-left: 50px;
        color: gray;
    }

    .barra-superior-filtro{
        height: 35px;
    }

    .barra-superior button{
        margin: 0.5rem 2rem;
        font-size: 0.8rem;
    }

    .barra-superior div{
        margin-left: 10px;
    }

    .barra-superior span{
        line-height: 50px;
        margin-left: 25px;
        font-weight: 500;
    }

    .lista-de-conversas{
        padding: 0;
        background:white;
    }

    .conversa-ativa{
        padding: 0;
        background:#E5DDD5;
        position: relative;
    }

    .mensagens{
        height: 83vh;
        margin: 10px 0;
        overflow-y: auto;
        display: grid;
    }

    .columns:last-child{
        margin: 0 -8px;
    }

    .section{
        padding: unset;
    }

    .container{
        max-width: unset;
    }

    .lista-mensagens{
        display: none;
        justify-content: flex-end;
        flex-direction: column;
    }

    .barra-inferior{
        display: flex;
        bottom:0;
        width: 100%;
        padding: 10px;
        position: absolute;
        background: #f0f0f0;
    }

    .barra-inferior input{
        border:none;
        padding: 10px;
        margin: 0 0 0 10px;
        width: 90%;
        border-radius: 15px;
        font-size: 16px;
    }

    .barra-inferior.barra-inferior-template input{
        margin: 0 0 0 50px!important;
    }

    .barra-inferior button{
        display: flex;
        justify-content: center;
        margin: auto;
    }

    .btn-acoes{
        margin-left: 10px!important;
        padding: 12px;
        border-radius: 50px;
    }

    .btn-acoes:first-child{
        margin-left: 50px!important;
    }

    .btn-acoes:last-child{
        margin-right: 50px!important;
    }

    .btn-acoes:last-child .icon{
        transform: scale(0.9);
    }

    .icon-config{
        float: right;
        margin: 13px;
        color: gray;
        cursor: pointer;
    }

    a.dropdown-item:hover{
        background-color: #3273dc;
        color: #fff;
    }

    .dropdown-content{
        box-shadow: 0em 0em 1em 0em rgb(10 10 10 /10%), 0 0 0 1px rgb(10 10 10 / 10%);
    }  

</style>
