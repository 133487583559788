<template>
    <div class="control">
        <label class="label">{{ label }}</label>
        <div class="switch">
            <input :id="id" type="checkbox" :value="value" @input="$emit('input', $event.target.checked)" :checked="checked">
            <label :for="id" :data-label="dataLabel"></label>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SwitchButton",
        props: ['label', 'id', 'value', 'checked', 'dataLabel']
    }
</script>

<style>
    .switch {
        position: relative;
        margin-bottom: 1rem;
        outline: 0;
        user-select: none;
        font-size: 1rem;
        min-height: 2rem;
        height: 2rem;
    }

    .switch input {
        position: absolute;
        margin-bottom: 0;
        opacity: 0;
    }

    .switch input:checked ~ label {
        background: #48c774;
    }

    .switch input:checked ~ label::before {
        left: 1.8rem;
    }

    .switch label {
        position: relative;
        display: block;
        width: 4rem;
        height: 40px;
        border-radius: 50px;
        background: #b5b5b5;
        transition: all 0.25s ease-out;
        line-height: 2rem;
        cursor: pointer;
    }

    input + .switch label {
        margin: 0;
    }

    .switch label::after {
        position: absolute;
        content: attr(data-label);
        left: 4.5rem;
        width: 100%;
    }

    .switch label::before {
        position: absolute;
        top: 0.25rem;
        left: 0.25rem;
        display: block;
        width: 32px;
        height: 32px;
        transform: translate3d(0, 0, 0);
        border-radius: 50px;
        background: white;
        transition: all 0.25s ease-out;
        content: "";
    }
</style>