import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMask from 'v-mask'
import Mensagens from './components/mensagens/Mensagens.vue'
import PaginaInicial from './components/PaginaInicial.vue'
import Usuarios from './components/usuarios/Usuarios.vue'
import Modelos from './components/modelos/Modelos.vue'
import Cliente from './components/Cliente.vue'
import Erro404 from './components/Erro404.vue'
import Button from './components/layout/Button.vue'
import InputText from './components/layout/InputText.vue'
import Textarea from './components/layout/Textarea.vue'
import Select from './components/layout/Select.vue'
import SwitchButton from './components/layout/SwitchButton.vue'
import InputFiltro from './components/layout/InputFiltro.vue'
import Loader from './components/layout/Loader.vue'

Vue.use(VueMask)
Vue.use(VueRouter)

Vue.config.productionTip = false

Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('Textarea', Textarea)
Vue.component('Select', Select)
Vue.component('SwitchButton', SwitchButton)
Vue.component('InputFiltro', InputFiltro)
Vue.component('Loader', Loader)

const routes = {
    '/': PaginaInicial,
    '/mensagens': Mensagens,
    '/usuarios': Usuarios,
    '/modelos': Modelos,
    '/cliente': Cliente
}

new Vue({
    el: '#app',
    data: {
        currentRoute: window.location.pathname
    },
    computed: {
        ViewComponent () {
            return routes[this.currentRoute] || Erro404
        }
    },
    render (h) { return h(this.ViewComponent) }
})
