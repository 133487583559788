<template>
    <button :class="`button ${classes}`" :disabled="disabled" @click="onClick">
        <div class="icon" v-if="icone">
            <i :class="`gg-${icone}`"></i>
        </div>
        <div v-if="titulo">{{ titulo }}</div>
    </button>
</template>

<script>
    export default {
        name: "Button",
        props: ['classes', 'icone', 'titulo', 'disabled', 'onclick', 'parametro1', 'parametro2'],
        methods: {
            onClick() {
                if(typeof this.onclick === 'function'){
                    this.onclick(this.parametro1, this.parametro2)
                }
            }
        }
    }
</script>

<style>
    .btn-small{
        transform: scale(0.7);
        margin: 5px auto;
        display: flex;
        font-weight: 500;
    }

    .btn-voltar{
        float: right;
        margin-right: 13px!important;
    }

    .btn-visualizar-arquivados{
        transform: scale(0.7);
        margin: 5px auto;
        display: flex;
    }
</style>