<template>
    <div>
        <Loader v-if="carregando" />
        <div id="app" v-show="autenticacao.autenticado && !carregando">
            <section class="section">
                <div class="container">
                    <div class="columns janela-principal">
                        <div class="column conversa-ativa">
                            <div class="barra-superior">
                                <a href="/mensagens" class="is-pulled-left">

                                    <Button
                                        :classes="`btn-voltar is-link is-outlined`" 
                                        :icone="`chevron-left`" 
                                        :titulo="`Voltar`" 
                                    />

                                </a>
                                <span></span>
                            </div>
                            <div class="cliente">

                                <div class="detalhesCliente">

                                    <InputText
                                        :label="`Nome do cliente`" 
                                        :preenchido="validacao.nomeCompleto" 
                                        v-model="form.nomeCompleto"
                                    />

                                    <InputText
                                        :label="`Documento`" 
                                        :preenchido="validacao.documento" 
                                        v-model="form.documento"
                                    />

                                    <InputText
                                        :label="`URL webhook`" 
                                        :preenchido="validacao.whatsapp.webhook" 
                                        v-model="form.whatsapp.webhook"
                                    />

                                    <InputText
                                        :label="`Chave webhook`" 
                                        :preenchido="validacao.whatsapp.chaveWebhook" 
                                        v-model="form.whatsapp.chaveWebhook"
                                    />

                                    <div class="columns">

                                        <Select 
                                            :label="`Integrador`" 
                                            :preenchido="validacao.whatsapp.integrador" 
                                            class="column is-half"
                                            v-model="form.whatsapp.integrador"
                                        >
                                            <option value="" selected disabled>Selecione o integrador</option>
                                            <option value="ZENVIA">Zenvia</option>
                                            <option value="META">Meta</option>
                                        </Select>

                                        <InputText
                                            :label="`Número`" 
                                            :preenchido="validacao.whatsapp.numero" 
                                            class="column is-half"
                                            v-model="form.whatsapp.numero"
                                        />

                                    </div>                                

                                    <InputText
                                        :label="`Token`" 
                                        :preenchido="validacao.whatsapp.token" 
                                        v-model="form.whatsapp.token"
                                    />

                                    <div class="btn-control">

                                        <Button
                                            :classes="`is-link is-outlined`" 
                                            :onclick="atualizarCliente"
                                            :titulo="`Atualizar cliente`" 
                                        />

                                    </div>                                
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import moment from "moment"
    import { Buffer } from 'buffer'

    export default {
        name: "Cliente",
        data(){
            return {
                form: {
                    nomeCompleto: '',
                    documento: '',
                    whatsapp: {
                        webhook: '',
                        chaveWebhook: '',
                        integrador: '',
                        numero: '',
                        token: ''
                    }
                },
                validacao: {
                    nomeCompleto: true,
                    documento: true,
                    whatsapp: {
                        webhook: true,
                        chaveWebhook: true,
                        integrador: true,
                        numero: true,
                        token: true
                    }
                },
                autenticacao: {
                    autenticado: false,
                    id_token: '',
                    access_token: ''
                },
                carregando: true
            }
        },
        async created(){
            /**
             * Define o titulo da pagina
             */
            document.title = 'Gerenciador de usuários'

            /**
             * Realizar a autenticação
             */
            await this.autenticar()

            /**
             * Verifica se esta autenticado
             */
            if(this.autenticacao.autenticado){
                /**
                 * Lista os clientes
                 */
                await this.detalhesCliente()
            }            
        },
        async mounted() {
            /**
             * Realizar a autenticação
             */
            await this.autenticar()
        },
        methods: {
            /**
             * Função para realizar a autenticação da aplicação
             */
            autenticar(){
                /**
                 * Busca os parametros da URL
                 *
                 * @var {object} oParams
                 */
                const oParams = new URLSearchParams(window.location.hash)

                /**
                 * Limpa os dados do URL
                 */
                window.location.hash = ''
                
                /**
                 * Verifica se existe o token de autenticação
                 */
                if(!oParams.get('access_token') && !localStorage.getItem('access_token')){
                    /**
                     * Redireciona para a autenticação caso não exista
                     */
                    window.location.href = process.env.VUE_APP_ENDPOINT_AUTH

                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.autenticado = false

                    return false
                }

                if(oParams.get('access_token')){
                    localStorage.setItem('id_token', oParams.get('#id_token'))
                    localStorage.setItem('access_token', oParams.get('access_token'))
                    localStorage.setItem('token_type', oParams.get('token_type'))
                }

                /**
                 * Define os dados nas variaveis
                 */
                this.autenticacao.autenticado = true
                this.autenticacao.id_token = oParams.get('#id_token') ?? localStorage.getItem('id_token')
                this.autenticacao.access_token = oParams.get('access_token') ?? localStorage.getItem('access_token')
                this.autenticacao.token_type = oParams.get('token_type') ?? localStorage.getItem('token_type')

                /**
                 * Transforma o token JWT em um array, quebrando pelo caractere ponto (.)
                 *
                 * @var {string} sHeader
                 * @var {string} sPayload
                 * @var {string} sSignature
                 */
                const [sHeader, sPayload, sSignature] = this.autenticacao.access_token
                    .toString()
                    .split('.')

                /**
                 * Transforma o dado em base64 para string legível
                 *
                 * @var {Object} oDadosDescriptografados
                 */
                const oDadosDescriptografados = JSON.parse(
                    (Buffer.from(sPayload, 'base64'))
                    .toString('ascii')
                )

                /**
                 * Verifica se o cliente é admin
                 */
                 if(!oDadosDescriptografados['cognito:groups'].includes('Administradores')){
                    /**
                     * Redireciona para a autenticação
                     */
                    window.location.href = process.env.VUE_APP_ENDPOINT_AUTH

                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.autenticado = false

                    return false
                }

                /**
                 * Verifica se o token não está expirado
                 */
                if(moment().isAfter(moment.unix(oDadosDescriptografados.exp))){
                    /**
                     * Redireciona para a autenticação
                     */
                    window.location.href = process.env.VUE_APP_ENDPOINT_AUTH

                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.autenticado = false

                    return false
                }

                /**
                 * Remove o # qque é informado na URL de retorno da AWS
                 */
                if (window.location.href.charAt(window.location.href.length - 1) == "#") {
                    window.location.replace(window.location.href.split("#")[0]);
                }

                return true
            },
            /**
             * Função para buscar os dados do cliente
             */
            async detalhesCliente(){
                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'GET',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para buscar os clientes
                 * 
                 * @var {array} aClientes
                 */
                let aClientes = await fetch(`${process.env.VUE_APP_ENDPOINT_API_CLIENTES}/v1/clientes`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(error.codigo && typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao ?? 'Erro de sistema'}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        if(response.body.whatsapp){
                            if(response.body.whatsapp.integrador == 'META'){
                                response.body.whatsapp.token = response.body.whatsapp.metaTokenIntegracao
                                response.body.whatsapp.numero = response.body.whatsapp.metaIdNumeroTelefone
                            }

                            if(response.body.whatsapp.integrador == 'ZENVIA'){
                                response.body.whatsapp.token = response.body.whatsapp.tokenIntegracao
                            }
                        }

                        return response.body
                    })

                if(!aClientes){
                    return
                }

                /**
                 * Define os dados do cliente no objeto do vue
                 */
                this.form = aClientes
                this.carregando = false
            },
            /**
             * Função para buscar os dados do cliente
             */
            async atualizarCliente(){
                /**
                 * Realiza a validação dos campos
                 * 
                 * @var {boolean} bValidacao
                 */
                const bValidacao = this.validarCampos()

                if(!bValidacao){
                    return
                }

                /**
                 * Adiciona o loading no botão
                 */
                document.querySelector('.btn-control button').classList.add('is-loading')    
                
                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                const oForm = JSON.parse(JSON.stringify(this.form))

                if(oForm.whatsapp.integrador == 'META'){
                    oForm.whatsapp.metaTokenIntegracao = oForm.whatsapp.token
                    oForm.whatsapp.metaIdNumeroTelefone = oForm.whatsapp.numero

                    delete oForm.whatsapp.numero
                }

                if(oForm.whatsapp.integrador == 'ZENVIA'){
                    oForm.whatsapp.tokenIntegracao = oForm.whatsapp.token
                }

                delete oForm.whatsapp.token

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'PUT',
                    headers: oHeaders,
                    body: JSON.stringify(oForm),
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para buscar os clientes
                 * 
                 * @var {array} aClientes
                 */
                let aClientes = await fetch(`${process.env.VUE_APP_ENDPOINT_API_CLIENTES}/v1/clientes`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(error.codigo && typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao ?? 'Erro de sistema'}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }                       

                        return response
                    })

                if(!aClientes){
                    return
                }

                toastAlert('Cliente atualizado com sucesso', 'success')
                
                /**
                 * Remove o loading no botão
                 */
                document.querySelector('.btn-control button').classList.remove('is-loading')  
            },
            /**
             * Função para validar os campos
             */
            validarCampos(){
                /**
                 * Define a variavel do retorno
                 * 
                 * @var {boolean} bValidacao
                 */
                let bRetornoValidacao = true

                Object.keys(this.validacao).map((sCampo) => {
                    if(sCampo == 'whatsapp'){
                        Object.keys(this.form.whatsapp).map((sCampoWhatsapp) => {
                            if(this.form.whatsapp[sCampoWhatsapp] == '' && sCampoWhatsapp != 'mensagemRetornoPadrao'){
                                this.validacao.whatsapp[sCampoWhatsapp] = false
                                bRetornoValidacao = false
                            }
                        })
                    }else{
                        if(this.form[sCampo] == ''){
                            this.validacao[sCampo] = false
                            bRetornoValidacao = false
                        }
                    }                    
                })

                if(!bRetornoValidacao){
                    toastAlert("Campo obrigatório não informado")
                }

                return bRetornoValidacao
            }
        }
    }
</script>

<style>
    html{
        overflow: hidden;
    }

    .title:not(:last-child), .subtitle:not(:last-child){
        margin-bottom: 0.5em;
    }

    .inp-btn{
        color: #bbb
    }

    .janela-principal{
        height: 100vh;
        overflow: hidden;
    }

    .clientes{
        height: 89vh;
        overflow-y: auto;
    }

    .barra-superior{
        margin: 0;
        height: 50px;
        background: #EDEDED;
        border-right: 1px solid #E1E1E1;
        border-bottom: 1px solid rgb(200, 200, 200);
    }

    .barra-superior .gg-search{
        position: absolute;
        margin: 9px 27px;
        transform: scale(var(--ggs,0.6));
    }

    .barra-superior input{
        width: 100%;
        border: none;
        margin: 0;
        height: 100%;
        outline: none;
        padding-left: 50px;
        color: gray;
    }

    .barra-superior-filtro{
        height: 35px;
    }

    .barra-superior button{
        margin: 0.5rem 2rem;
        font-size: 0.8rem;
    }

    .barra-superior div{
        margin-left: 10px;
    }

    .barra-superior span{
        line-height: 50px;
        margin-left: 25px;
        font-weight: 500;
    }

    .lista-de-conversas{
        padding: 0;
        background:white;
    }

    .conversa-ativa{
        padding: 0;
        background:#E5DDD5;
        position: relative;
    }

    .cliente{
        height: 100vh;
        margin: 10px 0;
        overflow-y: auto;
        display: grid;
    }

    .columns:last-child{
        margin: 0 -8px;
    }

    .section{
        padding: unset;
    }

    .container{
        max-width: unset;
    }

    .detalhesCliente {
        margin: 2vh auto;
        position: relative;
        width: 44rem;
        background: #fff;
        padding: 1rem 2rem;
        border-radius: 1rem;
        height: -moz-fit-content;
        height: fit-content;
    }

    .btn-control {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
</style>
