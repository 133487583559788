<template>
    <div class="pagina-inicial">
        <p class="pagina-inicial-descricao">Em desenvolvimento.</p>
    </div>
</template>

<script>
    export default {
        name: 'PaginaInicial',
    }
</script>

<style>
    .pagina-inicial {
        text-align: center;
    }

    .pagina-inicial-descricao {
        font-size: 2rem;
        margin-top: 10rem;
        color: #616161;
    }
</style>