<template>
    <div class="detalhesModelo">
        
        <div class="columns">
            <InputText
                :label="`Título do modelo`" 
                :preenchido="validacao.titulo" 
                class="column is-half"
                v-model="form.titulo"
            />

            <Select 
                :label="`Integrador`" 
                :preenchido="validacao.integrador" 
                class="column is-half"
                v-model="form.integrador"
            >
                <option value="" selected disabled>Selecione o integrador</option>
                <option value="Zenvia">Zenvia</option>
                <option value="Meta">Meta</option>
            </Select>
        </div>

        
        <div class="columns">

            <InputText
                :label="`Identificador do modelo`" 
                :preenchido="validacao.identificador" 
                class="column"
                :class="{ 'is-four-fifths': modelo.titulo != '' }"
                v-model="form.identificador"
            />

            <SwitchButton
                v-if="modelo.titulo != ''"
                class="column"
                :label="`Status`" 
                :id="`status`" 
                :checked="form.ativo" 
                :data-label="form.ativo == true ? 'Ativo' : 'Inativo'" 
                v-model="form.ativo"
            />

        </div>

        <Textarea
            :label="`Texto do modelo`" 
            :preenchido="validacao.texto" 
            :rows="4" 
            v-model="form.texto"
        />

        <hr>

        <div class="control" 
            v-for="(campo, idxCampo) in form.campos"
            :key="idxCampo"
        >
            <div class="columns">

                <InputText
                    :label="`Identificador da variável`" 
                    :preenchido="validacao.campos[idxCampo].id" 
                    class="column is-half"
                    v-model="form.campos[idxCampo].id"
                />

                <InputText
                    :label="`Título da variável`" 
                    :preenchido="validacao.campos[idxCampo]?.label" 
                    class="column is-half"
                    v-model="form.campos[idxCampo].label"
                />

            </div>
            <div class="columns">

                <Select 
                    :label="`Tipo da variável`" 
                    :preenchido="validacao.campos[idxCampo]?.tipo" 
                    class="column is-half"
                    v-model="form.campos[idxCampo].tipo"
                >
                    <option value="" selected disabled>Selecione o tipo</option>
                    <option value="text">Texto</option>
                    <option value="date">Data</option>
                </Select>

                <Select 
                    :label="`É obrigatório?`" 
                    :preenchido="validacao.campos[idxCampo]?.obrigatorio" 
                    class="column is-half"
                    v-model="form.campos[idxCampo].obrigatorio"
                >
                    <option value="" selected disabled>Selecione uma opção</option>
                    <option value=true>Sim</option>
                    <option value=false>Não</option>
                </Select>

            </div>

            <Button
                :classes="`is-danger is-outlined btn-small`" 
                :icone="`close`" 
                :titulo="`Remover`" 
                :onclick="removerVariavel"
                :parametro1="idxCampo"
            />

            <hr>
        </div>

        <Button
            :classes="`is-link is-outlined btn-small`" 
            :icone="`math-plus`" 
            :titulo="`Adicionar variável`" 
            :onclick="adicionarVariavel"
        />

        <hr>
        
        <div class="btn-control">
            <Button
                v-if="modelo.titulo != ''"
                :classes="`is-link is-outlined`" 
                :titulo="`Atualizar modelo`" 
                :onclick="atualizarModelo"
            />
            <Button
                v-else
                :classes="`is-link is-outlined`" 
                :titulo="`Cadastrar modelo`" 
                :onclick="cadastrarModelo"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "DetalhesModelo",
        props: ["detalhesModelo", "modelo", "autenticacao", "listarModelos", "visualizarModelo"],
        data() {
            return {
                grupos: [],
                form: {
                    titulo: this.modelo.titulo,
                    texto: this.modelo.texto,
                    identificador: this.modelo.identificadorTemplateZenvia && this.modelo.identificadorTemplateZenvia != "" ? this.modelo.identificadorTemplateZenvia : this.modelo.identificadorTemplateMeta,
                    campos: this.modelo.campos ?? []
                },
                validacao: {
                    titulo: true,
                    integrador: true,
                    texto: true,
                    identificador: true,
                    campos: this.modelo?.campos?.map(obj => {
                        return Object.fromEntries(
                            Object.entries(obj).map(([key, value]) => [key, true])
                        )
                    }) ?? []
                }
            }
        },
        async created() {
            /**
             * Define os dados
             */
            this.form.integrador = this.modelo.identificadorTemplateZenvia && this.modelo.identificadorTemplateZenvia != '' ? 'Zenvia' : ''
            this.form.integrador = this.modelo.identificadorTemplateMeta && this.modelo.identificadorTemplateMeta != '' ? 'Meta' : this.form.integrador

            if(this.modelo.titulo != ''){
                this.form.ativo = this.modelo.status == 'Ativo' ? true : false
            }
        },
        methods: {
            /**
             * Função para popular os dados do modelo
             */
            async cadastrarModelo() {
                /**
                 * Realiza a validação dos campos
                 * 
                 * @var {boolean} bValidacao
                 */
                const bValidacao = this.validarCampos()

                if(!bValidacao){
                    return
                }

                /**
                 * Adiciona o loading no botão
                 */
                document.querySelector('.btn-control button').classList.add('is-loading')

                /**
                 * Define os headers da requisição
                 *
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os dados do form
                 * 
                 * @var {object} oForm
                 */
                 const oForm = JSON.parse(JSON.stringify(this.form))

                if(oForm.integrador == 'Meta'){
                    oForm.identificadorTemplateMeta = oForm.identificador
                    oForm.identificadorTemplateZenvia = ''
                }else{
                    oForm.identificadorTemplateZenvia = oForm.identificador
                    oForm.identificadorTemplateMeta = ''
                }

                /**
                 * Define os opções da requisição
                 *
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {
                    method: "POST",
                    headers: oHeaders,
                    body: JSON.stringify(oForm),
                    mode: "cors",
                    redirect: "follow"
                }

                /**
                 * Realiza a requisição para alterar os dados do modelo
                 *
                 * @var {mixed} mCadastro
                 */
                const mCadastro = await fetch(`${process.env.VUE_APP_ENDPOINT_API}/v1/whatsapp/templates`, oOpcoes)
                    .then(function (response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if (typeof error.codigo == "string") {
                                    error.codigo = `${error.codigo} -`
                                }else {
                                    error.codigo = ""
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            })
                        }

                        return response.json()
                    })
                    .then(function (response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })

                if (!mCadastro) {
                    return
                }

                await this.listarModelos()
        
                toastAlert("Modelo cadastrado com sucesso", "success")

                await this.visualizarModelo(mCadastro.hashTemplateInterno)
            },
            /**
             * Função para popular os dados do modelo
             */
            async atualizarModelo() {
                /**
                 * Realiza a validação dos campos
                 * 
                 * @var {boolean} bValidacao
                 */
                const bValidacao = this.validarCampos()

                if(!bValidacao){
                    return
                }

                /**
                 * Adiciona o loading no botão
                 */
                document.querySelector('.btn-control button').classList.add('is-loading')

                /**
                 * Define os headers da requisição
                 *
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os dados do form
                 * 
                 * @var {object} oForm
                 */
                const oForm = JSON.parse(JSON.stringify(this.form))

                if(oForm.integrador == 'Meta'){
                    oForm.identificadorTemplateMeta = oForm.identificador
                    oForm.identificadorTemplateZenvia = ''
                }else{
                    oForm.identificadorTemplateZenvia = oForm.identificador
                    oForm.identificadorTemplateMeta = ''
                }

                oForm.campos.map((oCampo, iIndice) => {
                    oForm.campos[iIndice].obrigatorio = oCampo.obrigatorio == 'true' ? true : false
                })

                /**
                 * Define os opções da requisição
                 *
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {
                    method: "PUT",
                    headers: oHeaders,
                    body: JSON.stringify(oForm),
                    mode: "cors",
                    redirect: "follow"
                }

                /**
                 * Realiza a requisição para alterar os dados do modelo
                 *
                 * @var {mixed} mAtualizar
                 */
                const mAtualizar = await fetch(`${process.env.VUE_APP_ENDPOINT_API}/v1/whatsapp/templates/${this.modelo.hashTemplateInterno}`, oOpcoes)
                    .then(function (response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if (typeof error.codigo == "string") {
                                    error.codigo = `${error.codigo} -`
                                }else {
                                    error.codigo = ""
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            })
                        }

                        return response.json()
                    })
                    .then(function (response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })

                if (!mAtualizar) {
                    return
                }

                await this.listarModelos()

                toastAlert("Modelo atualizado com sucesso", "success")

                await this.visualizarModelo(mAtualizar.hashTemplateInterno)
            },
            /**
             * Função para adicionar uma nova variavel
             */
            adicionarVariavel() {
                this.form.campos.push({
                    id: "",
                    label: "",
                    obrigatorio: "",
                    tipo: ""
                })

                this.validacao.campos.push({
                    id: true,
                    label: true,
                    obrigatorio: true,
                    tipo: true
                })
            },
            /**
             * Função para adicionar uma nova variavel
             */
            removerVariavel(iIndice) {
                this.form.campos.splice(iIndice, 1)
                this.validacao.campos.splice(iIndice, 1)
            },
            /**
             * Função para validar os campos
             */
            validarCampos(){
                /**
                 * Define a variavel do retorno
                 * 
                 * @var {boolean} bValidacao
                 */
                let bRetornoValidacao = true

                Object.keys(this.validacao).map((sCampo) => {
                    if((this.form[sCampo]?.length < 1 || typeof this.form[sCampo] === 'undefined') && sCampo != 'campos'){
                        this.validacao[sCampo] = false
                        bRetornoValidacao = false
                    }
                })

                this.form.campos?.map((oCampo, iIndice) => {
                    Object.keys(oCampo).map((sCampo) => {
                        if(this.form.campos[iIndice][sCampo]?.length < 1 || typeof this.form.campos[iIndice][sCampo] === 'undefined'){        
                            this.validacao.campos[iIndice] = this.validacao.campos[iIndice] ? this.validacao.campos[iIndice] : []
                            this.validacao.campos[iIndice][sCampo] = false

                            bRetornoValidacao = false
                        }
                    })
                })

                if(!bRetornoValidacao){
                    toastAlert("Campo obrigatório não informado")
                }

                return bRetornoValidacao
            }
        }
    }
</script>

<style scoped>
    .detalhesModelo{
        margin: 8vh auto;
        position: relative;
        width: 44rem;
        background: #fff;
        padding: 1rem 2rem;
        border-radius: 1rem;
        height: fit-content;
    }

    select, .select{
        width: 100%;
    }

    .label{
        margin-top: 0.8rem
    }

    .btn-control{
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        margin-bottom: 1rem;
    }

    .switch {
        position: relative;
        margin-bottom: 1rem;
        outline: 0;
        user-select: none;
        font-size: 1rem;
        min-height: 2rem;
        height: 2rem;
    }

    .switch input {
        position: absolute;
        margin-bottom: 0;
        opacity: 0;
    }

    .switch input:checked ~ label {
        background: #48c774;
    }

    .switch input:checked ~ label::before {
        left: 2.25rem;
    }

    .switch label {
        position: relative;
        display: block;
        width: 4rem;
        height: 2rem;
        border-radius: 3px;
        background: #b5b5b5;
        transition: all 0.25s ease-out;
        line-height: 2rem;
        cursor: pointer;
    }

    input + .switch label {
        margin: 0;
    }

    .switch label::after {
        position: absolute;
        content: attr(data-label);
        left: 4.5rem;
        width: 100%;
    }

    .switch label::before {
        position: absolute;
        top: 0.25rem;
        left: 0.25rem;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        transform: translate3d(0, 0, 0);
        border-radius: 3px;
        background: white;
        transition: all 0.25s ease-out;
        content: "";
    }

    .switch input:checked ~ label::before {
        left: 2.25rem;
    }
</style>