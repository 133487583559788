<template>
    <div class="control">
        <label class="label">{{ label }}</label>
        <input 
            class="input" 
            :class="{ 'is-danger': !preenchido && preenchidoInput }"
            @keyup="atualizarPreenchimento($event.target.value)"
            :type="tipo ?? 'text'" 
            v-mask="mascara" 
            :disabled="disabled" 
            :value="value" 
            @input="$emit('input', $event.target.value)"
        >
        <p class="help is-danger" v-if="!preenchido && preenchidoInput">Campo obrigatório</p>
    </div>
</template>

<script>
export default {
    name: "InputText",
    props: ['label', 'value', 'tipo', 'mascara', 'disabled', 'preenchido'],
    data() {
        return {
            preenchidoInput: typeof this.preenchido !== 'undefined' ? true : false
        }
    },
    methods: {
        atualizarPreenchimento(sValor) {
            if(sValor == ''){
                this.preenchidoInput = true
            }else{
                this.preenchidoInput = false
            }
        }
    }
}
</script>