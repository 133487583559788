<template>
    <div class="item" :class="{ativo: usuarioAtivo}">
        <div class="title is-6">
            {{nome}} 
            <div class="dataTitulo is-6">
                <span class="tag" :class="{'is-success': status == 'Ativo', 'is-danger': status == 'Inativo'}">{{ status }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Usuario',
        props:['nome', 'status', 'usuarioAtivo'],
    }
</script>

<style>
    .subtitle{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: gray;
    }

    .subtitle.is-6{
        padding-right: 5px;
        width: -webkit-fill-available;
    }

    .ultimaMensagem{
        display: flex;
    }

    .ultimaMensagem .tag{
        border-radius: 50px;
        font-size: 9px;
    }

    .item{
        border-bottom: 1px solid #F2F2F2;
        padding: 15px;
        margin-bottom: 0 !important;
        padding-left: 2rem !important;
    }
    
    .item:hover{
        background: #F5F5F5;
        cursor: pointer;
    }

    .item.ativo{
        background: rgb(235, 235, 235);
    }

    .dataTitulo{
        margin-left: 25px;
        font-size: 10px;
        float: right;
        margin-top: -3px;
        color: gray;
    }
</style>