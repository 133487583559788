<template>
    <div class="novaMensagem">

        <InputText
            :label="`Nome do usuário`" 
            :preenchido="validacao.nomeUsuario"
            :disabled="!novoUsuario"
            v-model="form.nomeUsuario"
        />

        <InputText
            :label="`Email`" 
            :preenchido="validacao.email"
            v-model="form.email"
        />

        
        <InputText
            :label="`Número do telefone`" 
            :mascara="'+## (##) #####-####'"
            :preenchido="validacao.telefone"
            v-model="form.telefone"
        />

        <Select 
            :label="`Grupo`" 
            :preenchido="validacao.grupo"
            v-model="form.grupo"
        >
            <option value="" selected disabled>Selecione o grupo</option>
            <option 
                v-for="(grupo, idxGrupo) in grupos"
                :key="idxGrupo"
                :value="grupo.nome"
            >
                {{ grupo.nome }}
            </option>
        </Select>

        <SwitchButton
            v-if="!novoUsuario"
            :label="`Status`" 
            :id="`status`" 
            :checked="usuario.status == 'Ativo'" 
            :data-label="status == true ? 'Ativo' : 'Inativo'" 
            v-model="status"
        />

        <div class="btn-control">

            <Button
                v-if="usuario.nomeUsuario != ''"
                :classes="`is-link is-outlined`" 
                :titulo="`Atualizar usuário`" 
                :onclick="atualizarUsuario"
            />

            <Button
                v-else
                :classes="`is-link is-outlined`" 
                :titulo="`Cadastrar usuário`" 
                :onclick="cadastrarUsuario"
            />

        </div>
    </div>
</template>

<script>
    export default {
        name: "DetalhesUsuario",
        props: ['novoUsuario', 'usuario', 'autenticacao', 'listarUsuarios', 'visualizarUsuario', 'removeLoader'],
        data(){
            return {
                status: this.usuario.status == 'Ativo',
                grupos: [],
                form: {
                    nomeUsuario: this.usuario.nomeUsuario,
                    email: '',
                    telefone: '',
                    grupo: ''
                },
                validacao: {
                    nomeUsuario: true,
                    email: true,
                    telefone: true,
                    grupo: true
                }
            }
        },
        async created() {
            /**
             * Lista os templates
             */
            await this.listarGrupos()  

            /**
             * Popula os dados do usuario
             */
            this.popularDados()
        },
        methods: {
            /**
             * Função para listar os grupos
             */
            async listarGrupos(){
                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'GET',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para buscar os grupos
                 * 
                 * @var {array} aGrupos
                 */
                var aGrupos = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario/grupo/cognito`, oOpcoes)
                    .then(function(response) {
                        return response.json();
                    })
                    .then(function(response) {
                        return response.data
                    })
                    .catch(error => {
                        console.error(error)
                        
                        toastAlert(`${error.codigo} - ${error.descricao}`)
                    })
                
                /**
                 * Define os grupos no objeto do vue
                 */
                this.grupos = aGrupos
            },
            /**
             * Função para popular os dados do usuario
             */
            popularDados(){          
                if(this.usuario.atributos){
                    this.usuario.atributos.map((oAtributo) => {
                        if(oAtributo.nome == 'phone_number'){
                            this.form.telefone = oAtributo.valor
                        }
                        
                        if(oAtributo.nome == 'email'){
                            this.form.email = oAtributo.valor
                        }
                    })
                }

                if(this.usuario.grupos){
                    this.form.grupo = this.usuario.grupos[0] ?? ''
                }

                /**
                 * Remove o loader
                 */
                this.removeLoader()
            },
            /**
             * Função para popular os dados do usuario
             */
            async cadastrarUsuario(){  
                /**
                 * Realiza a validação dos campos
                 * 
                 * @var {boolean} bValidacao
                 */
                 const bValidacao = this.validarCampos()

                if(!bValidacao){
                    return
                }

                /**
                 * Adiciona o loading no botão
                 */
                document.querySelector('.btn-control button').classList.add('is-loading')

                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os dados do form
                 * 
                 * @var {object} oForm
                 */
                const oForm = JSON.parse(JSON.stringify(this.form))

                /**
                 * Formata o telefone
                 */
                oForm.telefone = `+${oForm.telefone.replace(/\D/g, "")}`

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'POST',
                    headers: oHeaders,
                    body: JSON.stringify(oForm),
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para alterar os dados do usuario
                 * 
                 * @var {mixed} mCadastro
                 */
                const mCadastro = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario/cognito`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }

                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })

                if(!mCadastro){
                    return
                }

                await this.listarUsuarios()

                toastAlert('Usuário cadastrado com sucesso', 'success')
                
                /**
                 * Remove o loading no botão
                 */
                document.querySelector('.btn-control button').classList.remove('is-loading')                

                await this.visualizarUsuario(this.form.nomeUsuario)
            },
            /**
             * Função para popular os dados do usuario
             */
            async atualizarUsuario(){     
                /**
                 * Realiza a validação dos campos
                 * 
                 * @var {boolean} bValidacao
                 */
                 const bValidacao = this.validarCampos()

                if(!bValidacao){
                    return
                }

                /**
                 * Adiciona o loading no botão
                 */
                document.querySelector('.btn-control button').classList.add('is-loading')

                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os dados do form
                 * 
                 * @var {object} oForm
                 */
                const oForm = JSON.parse(JSON.stringify(this.form))

                /**
                 * Remove o nome do usuario do formulario
                 */
                delete oForm.nomeUsuario

                /**
                 * Formata o telefone
                 */
                oForm.telefone = `+${oForm.telefone.replace(/\D/g, "")}`

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'PUT',
                    headers: oHeaders,
                    body: JSON.stringify(oForm),
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para alterar os dados do usuario
                 * 
                 * @var {mixed} mAtualizar
                 */
                const mAtualzar = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario/cognito/${this.usuario.nomeUsuario}`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        return response
                    })

                if(!mAtualzar){
                    return
                }

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoesStatus
                 */
                const oOpcoesStatus = {  
                    method: 'PATCH',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para alterar o status
                 * 
                 * @var {array} aStatus
                 */
                const aStatus = await fetch(`${process.env.VUE_APP_ENDPOINT_API_USUARIOS}/v1/usuario/cognito/${this.usuario.nomeUsuario}/status/${this.status == true ? 'ativar' : 'inativar'}`, oOpcoesStatus)
                    .then(function(response) {
                        return response.json()
                    })
                    .then(function(response) {
                        return response
                    })

                await this.listarUsuarios()

                toastAlert('Usuário atualizado com sucesso', 'success')

                /**
                 * Remove o loading no botão
                 */
                document.querySelector('.btn-control button').classList.remove('is-loading')
            },
            /**
             * Função para validar os campos
             */
            validarCampos(){
                /**
                 * Define a variavel do retorno
                 * 
                 * @var {boolean} bValidacao
                 */
                let bRetornoValidacao = true

                Object.keys(this.validacao).map((sCampo) => {
                    if(this.form[sCampo] == ''){
                        this.validacao[sCampo] = false
                        bRetornoValidacao = false
                    }
                })

                if(!bRetornoValidacao){
                    toastAlert("Campo obrigatório não informado")
                }

                return bRetornoValidacao
            }
        }
    };
</script>

<style scoped>
    .novaMensagem{
        margin: 8vh auto;
        position: relative;
        width: 44rem;
        background: #fff;
        padding: 1rem 2rem;
        border-radius: 1rem;
        height: fit-content;
    }

    select, .select{
        width: 100%;
    }

    .label{
        margin-top: 0.8rem
    }

    .btn-control{
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
</style>