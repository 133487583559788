<template>
    <div class="item" :class="{ativo: mensagemAtiva}">
        <div class="title is-6">{{nome}} <div class="dataTitulo is-6">{{dataMensagem}}</div></div>
        <div class="ultimaMensagem">
            <div class="subtitle is-6">{{ultimaMensagem}}</div>
            <span class="tag is-success" v-if="naoLidas > 0 && !mensagemAtiva">{{naoLidas}}</span>
            <span class="tag is-success" style="visibility: hidden;" v-else></span>

            
            <div class="dropdown is-right is-hoverable icon-config-contato">
                <div class="dropdown-trigger">
                    <i class="gg-chevron-down"></i>
                </div>
                <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                        <a href="javascript:void(0)" @click.prevent="arquivarConversa(numero, arquivado)" class="dropdown-item">
                            {{ arquivado == true ? 'Desarquivar' : 'Arquivar' }} conversa
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Contatos',
        props:['nome', 'numero', 'ultimaMensagem', 'dataMensagem', 'naoLidas', 'mensagemAtiva', 'arquivarConversa', 'arquivado']
    }
</script>

<style>
    .subtitle{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: gray;
    }

    .subtitle.is-6{
        padding-right: 5px;
        width: -webkit-fill-available;
    }

    .ultimaMensagem{
        display: flex;
    }

    .ultimaMensagem .tag{
        border-radius: 50px;
        font-size: 9px;
    }

    .item{
        border-bottom: 1px solid #F2F2F2;
        padding: 15px;
        margin-bottom: 0 !important;
        padding-left: 2rem !important;
    }
    
    .item:hover{
        background: #F5F5F5;
        cursor: pointer;
    }

    .item.ativo{
        background: rgb(235, 235, 235);
    }

    .dataTitulo{
        margin-left: 25px;
        font-size: 10px;
        float: right;
        margin-top: 6px;
        color: gray;
    }

    .icon-config-contato{
        margin: -2px -5px 0px 5px;
        display: none;
    }

    .icon-config-contato .dropdown-menu{
        margin-top: -10px;
    }

    .item:hover .icon-config-contato{
        display: block;
    }
</style>