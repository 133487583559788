<template>
    <div class="novaMensagem">

        <InputText
            :label="`Número do contato`" 
            :mascara="`+## (##) #####-####`" 
            :disabled="numero != ''"
            :preenchido="validacao.numero"
            v-model="form.numero"
        />

        <InputText
            :label="`Nome do contato`" 
            :preenchido="validacao.nome"
            v-model="form.nome"
        />

        <Select 
            :label="`Modelo da mensagem`" 
            :preenchido="validacao.modelo"
            :onchange="buscarTemplate"
            v-model="form.modelo"
        >
            <option value="" selected disabled>Selecione o modelo</option>
            <option 
                v-for="(template, iTemplate) in templates"
                :value="iTemplate"
            >
                {{ template.titulo }}
            </option>
        </Select>

        <div class="control" v-if="campos.length > 0">
            <div 
                v-for="(campo, iCampo) in campos"
                :key="iCampo"
            >

                <InputText
                    :key="`${form.modelo}_${campo.id}`" 
                    :label="campo.label" 
                    :tipo="campo.tipo" 
                    :preenchido="validacao.campos[campo.id]" 
                    v-model="form.campos[campo.id]"
                />

            </div>
        </div>

        <div class="btn-control" style="justify-content: space-between;">

            <Button
                :classes="`is-link is-outlined`" 
                :titulo="`Enviar mensagem`" 
                :onclick="enviarMensagemTemplate"
            />

            <Button
                :classes="`is-danger is-outlined`" 
                :icone="`close`" 
                :titulo="`Cancelar`" 
                :onclick="cancelarTemplate"
            />

        </div>
    </div>
</template>

<script>
    export default {
        name: "NovaMensagemTemplate",
        props: ['numero', 'nome', 'enviarMensagem', 'autenticacao', 'cancelarTemplate'],
        data(){
            return {
                form: {
                    numero: this.numero,
                    nome: this.nome,
                    modelo: '',
                    campos: [],
                },
                validacao: {
                    numero: true,
                    nome: true,
                    modelo: true,
                    campos: []
                },
                templates: [],
                hashTemplateInterno: '',
                campos: []
            }
        },
        async created() {
            /**
             * Lista os templates
             */
            await this.listarTemplates()
        },
        methods: {
            async listarTemplates(){
                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'GET',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para buscar os templates
                 * 
                 * @var {array} aTemplates
                 */
                var aTemplates = await fetch(`${process.env.VUE_APP_ENDPOINT_API}/v1/whatsapp/templates?pagina=1&registrosPorPagina=50&ativos=1`, oOpcoes)
                    .then(function(response) {
                        return response.json();
                    })
                    .then(function(response) {
                        return response.data
                    })
                    .catch(error => {
                        console.error(error)
                        
                        toastAlert(`${error.codigo} - ${error.descricao}`)
                    })
                
                /**
                 * Define os contato no objeto do vue
                 */
                this.templates = aTemplates
            },
            /**
             * Função para buscar o template na listagem
             */
            buscarTemplate(){
                this.hashTemplateInterno = this.templates[this.form.modelo].hashTemplateInterno
                this.campos = this.templates[this.form.modelo].campos
                this.validacao.campos = []
                this.campos.map((oCampo) => {
                    if(oCampo.obrigatorio == true){
                        this.validacao.campos[oCampo.id] = true
                    }
                })
            },
            /**
             * Função para enviar a mensagem via template
             */
            async enviarMensagemTemplate(){
                /**
                 * Realiza a validação dos campos
                 * 
                 * @var {boolean} bValidacao
                 */
                const bValidacao = this.validarCampos()

                if(!bValidacao){
                    return
                }
                
                /**
                 * Adiciona o loading no botão
                 */
                document.querySelector('.btn-control button').classList.add('is-loading')

                /**
                 * Inicia a variavel dos parametros
                 * 
                 * @var {object} oParametros
                 */
                const oParametros = {}

                this.campos.map(async (oCampo) => {
                    oParametros[oCampo.id] = this.form.campos[oCampo.id]
                })

                /**
                 * Define o objeto da nova mensagem
                 * 
                 * @var {object} oNovaMensagem
                 */
                const oNovaMensagem = {
                    "numeroDestinatario": this.form.numero.replace(/\D/g, ""),
                    "nomeDestinatario": this.form.nome,
                    "tipo": "template",
                    "template": this.hashTemplateInterno,
                    "parametros": oParametros
                }

                /**
                 * Envia a mensagem
                 */
                await this.enviarMensagem(oNovaMensagem)
            },
            /**
             * Função para validar os campos
             */
            validarCampos(){
                /**
                 * Define a variavel do retorno
                 * 
                 * @var {boolean} bValidacao
                 */
                let bRetornoValidacao = true

                Object.keys(this.validacao).map((sCampo) => {
                    if(this.form[sCampo] == '' && sCampo != 'campos'){
                        this.validacao[sCampo] = false
                        bRetornoValidacao = false
                    }
                })

                this.campos.map((oCampo) => {
                    if(oCampo.obrigatorio == true){
                        if(typeof this.form.campos[oCampo.id] === 'undefined'){
                            this.validacao.campos[oCampo.id] = false

                            /**
                             * Força a atualização do v-for
                             */
                            this.campos = JSON.parse(JSON.stringify(this.campos))

                            bRetornoValidacao = false
                        }
                    }
                })

                if(!bRetornoValidacao){
                    toastAlert("Campo obrigatório não informado")
                }

                return bRetornoValidacao
            }
        }
    };
</script>

<style>
    .novaMensagem{
        margin: 16vh auto;
        margin-top: 2vh!important;
        position: relative;
        width: 44rem;
        background: #fff;
        padding: 1rem 2rem;
        border-radius: 1rem;
        height: fit-content;
    }

    .novoContato{
        height: 100vh!important;
    }

    select, .select{
        width: 100%;
    }

    .label{
        margin-top: 0.8rem
    }

    .btn-control{
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
</style>