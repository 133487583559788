<template>
    <div>
        <Loader v-if="carregando" />
        <div id="app" v-show="autenticacao.autenticado && !carregando">
            <section class="section">
                <div class="container">
                    <div class="columns janela-principal">
                        <div class="column is-3 lista-de-conversas">
                            <div class="barra-superior">
                                <Button
                                    :classes="`is-link is-outlined`" 
                                    :icone="`add`" 
                                    :titulo="`Novo modelo`" 
                                    :onclick="visualizarModelo"
                                    :parametro1="``"
                                    :parametro2="true"
                                />
                                <a href="/mensagens">
                                    <Button
                                        :classes="`btn-voltar is-link is-outlined`" 
                                        :icone="`chevron-left`" 
                                        :titulo="`Voltar`" 
                                    />
                                </a>
                            </div>
                            <div class="barra-superior barra-superior-filtro">

                                <InputFiltro
                                    :onkeyup="listarModelos" 
                                    :placeholder="`Filtrar por título dos modelos`" 
                                    v-model="filtro"
                                />
                                
                            </div>
                            <div class="modelos">
                                <Modelo 
                                    v-for="(modelo, idxModelo) in modelos"
                                    :key="idxModelo" 
                                    :modelo-ativo="modelo.hashTemplateInterno == indiceAtivo"
                                    :titulo="modelo.titulo"
                                    :status="modelo.status"
                                    @click.native="visualizarModelo(modelo.hashTemplateInterno)"
                                />
                            </div>
                        </div>
                        <div class="column conversa-ativa">
                            <div class="barra-superior">
                                <span></span>
                            </div>
                            <div class="modelo">
                                <Loader v-if="carregandoModelo" />

                                <DetalhesModelo v-if="modelo != '' && !carregandoModelo"
                                    :key="modelo.id" 
                                    :modelo="modelo"
                                    :autenticacao="autenticacao"
                                    :novo-modelo="novoModelo"
                                    :listar-modelos="listarModelos"
                                    :visualizar-modelo="visualizarModelo"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import moment from "moment"
    import { Buffer } from 'buffer'
    import Modelo from './Modelo.vue'
    import DetalhesModelo from './DetalhesModelo.vue'

    export default {
        name: "Modelos",
        components: {
            Modelo,
            DetalhesModelo
        },
        data(){
            return {
                modelos: [
                    {
                        "titulo": "",
                        "id": "",
                        "hashTemplateInterno": ""
                    }
                ],
                modelosIdentificados: [],
                novoModelo: false,
                modelo: '',
                indiceAtivo: sessionStorage.getItem('indice'),
                novoModelo: false,
                novaMensagemModelo: true,
                carregando: true,
                carregandoModelo: false,
                filtro: '',
                autenticacao: {
                    autenticado: false,
                    id_token: '',
                    access_token: ''
                }
            }
        },
        async created(){
            /**
             * Define o titulo da pagina
             */
            document.title = 'Gerenciador de modelos'

            /**
             * Realizar a autenticação
             */
            await this.autenticar()

            /**
             * Verifica se esta autenticado
             */
            if(this.autenticacao.autenticado){
                /**
                 * Lista os modelos
                 */
                await this.listarModelos()

                /**
                 * Se existir modelo selecionado, mostra os detalhes dele
                 */
                if(this.modelosIdentificados[this.indiceAtivo]){
                    this.visualizarModelo(this.modelosIdentificados[this.indiceAtivo].hashTemplateInterno)
                }
            }            
        },
        async mounted() {
            /**
             * Realizar a autenticação
             */
            await this.autenticar()
        },
        methods: {
            /**
             * Função para realizar a autenticação da aplicação
             */
            autenticar(){
                /**
                 * Busca os parametros da URL
                 *
                 * @var {object} oParams
                 */
                const oParams = new URLSearchParams(window.location.hash)

                /**
                 * Limpa os dados do URL
                 */
                window.location.hash = ''
                
                /**
                 * Verifica se existe o token de autenticação
                 */
                if(!oParams.get('access_token') && !localStorage.getItem('access_token')){
                    /**
                     * Redireciona para a autenticação caso não exista
                     */
                    window.location.href = process.env.VUE_APP_ENDPOINT_AUTH

                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.autenticado = false

                    return false
                }

                if(oParams.get('access_token')){
                    localStorage.setItem('id_token', oParams.get('#id_token'))
                    localStorage.setItem('access_token', oParams.get('access_token'))
                    localStorage.setItem('token_type', oParams.get('token_type'))
                }

                /**
                 * Define os dados nas variaveis
                 */
                this.autenticacao.autenticado = true
                this.autenticacao.id_token = oParams.get('#id_token') ?? localStorage.getItem('id_token')
                this.autenticacao.access_token = oParams.get('access_token') ?? localStorage.getItem('access_token')
                this.autenticacao.token_type = oParams.get('token_type') ?? localStorage.getItem('token_type')

                /**
                 * Transforma o token JWT em um array, quebrando pelo caractere ponto (.)
                 *
                 * @var {string} sHeader
                 * @var {string} sPayload
                 * @var {string} sSignature
                 */
                const [sHeader, sPayload, sSignature] = this.autenticacao.access_token
                    .toString()
                    .split('.')

                /**
                 * Transforma o dado em base64 para string legível
                 *
                 * @var {Object} oDadosDescriptografados
                 */
                const oDadosDescriptografados = JSON.parse(
                    (Buffer.from(sPayload, 'base64'))
                    .toString('ascii')
                )

                /**
                 * Verifica se o modelo é admin
                 */
                 if(!oDadosDescriptografados['cognito:groups'].includes('Administradores')){
                    /**
                     * Redireciona para a autenticação
                     */
                    window.location.href = process.env.VUE_APP_ENDPOINT_AUTH

                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.autenticado = false

                    return false
                }

                /**
                 * Verifica se o token não está expirado
                 */
                if(moment().isAfter(moment.unix(oDadosDescriptografados.exp))){
                    /**
                     * Redireciona para a autenticação
                     */
                    window.location.href = process.env.VUE_APP_ENDPOINT_AUTH

                    /**
                     * Define os dados nas variaveis
                     */
                    this.autenticacao.autenticado = false

                    return false
                }

                /**
                 * Remove o # qque é informado na URL de retorno da AWS
                 */
                if (window.location.href.charAt(window.location.href.length - 1) == "#") {
                    window.location.replace(window.location.href.split("#")[0]);
                }

                return true
            },
            /**
             * Função para buscar os modelos
             */
            async listarModelos(){
                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'GET',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Inicia o array dos modelo que serão identificados pelo numero
                 * 
                 * @var {array} aModelosIdentificados
                 */
                let aModelosIdentificados = []

                /**
                 * Realiza a requisição para buscar os modelos
                 * 
                 * @var {array} aModelos
                 */
                let aModelos = await fetch(`${process.env.VUE_APP_ENDPOINT_API}/v1/whatsapp/templates`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(error.codigo && typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao ?? 'Erro de sistema'}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        /**
                         * Itera os modelos
                         */
                        response.data.map((aModelo) => {
                            /**
                             * Ordena os modelos com base na data da última mensagem
                             */
                            response.data.sort((a, b) => {
                                return new Date(b.titulo) - new Date(a.titulo)
                            })
                            
                            /**
                             * Adiciona o modelo no novo array com o indice sendo
                             * o número do telefone
                             */
                            aModelosIdentificados[aModelo.hashTemplateInterno] = aModelo

                            return aModelo
                        })

                        return response.data
                    })

                if(!aModelos){
                    return
                }
                
                aModelos = this.filtrarModelos(aModelos)

                /**
                 * Define os modelo no objeto do vue
                 */
                this.modelos = aModelos
                this.modelosIdentificados = aModelosIdentificados
                this.carregando = false
            },
            /**
             * Função para visualizar os detalhes do modelo
             * 
             * @param {int} sHash
             */
            async visualizarModelo(sHash, bNovoModelo = false){
                /**
                 * Define se é um novo modelo
                 */
                this.novoModelo = bNovoModelo
                this.carregandoModelo = !bNovoModelo

                /**
                 * Verifica se o nome foi informado
                 */
                if(!sHash || sHash == ""){
                    this.modelo = {
                        titulo: ''
                    }

                    
                    this.indiceAtivo = '' 

                    return
                }

                /**
                 * Verifica se foi realizado a troca de modelo
                 */
                if(this.indiceAtivo != sHash){
                    this.indiceAtivo = sHash 
                    sessionStorage.setItem('indice', sHash) 
                }

                /**
                 * Define os headers da requisição
                 * 
                 * @var {object} oHeaders
                 */
                const oHeaders = new Headers()                
                oHeaders.append("Content-Type", "application/json")
                oHeaders.append("Authorization", `${this.autenticacao.token_type} ${this.autenticacao.access_token}`)

                /**
                 * Define os opções da requisição
                 * 
                 * @var {object} oOpcoes
                 */
                const oOpcoes = {  
                    method: 'GET',
                    headers: oHeaders,
                    mode: 'cors',
                    redirect: 'follow'
                }

                /**
                 * Realiza a requisição para buscar os detalhes do modelo
                 * 
                 * @var {array} aModelo
                 */
                const aModelo = await fetch(`${process.env.VUE_APP_ENDPOINT_API}/v1/whatsapp/templates/${sHash}`, oOpcoes)
                    .then(function(response) {
                        if (!response.ok) {
                            return response.json().then(error => {
                                if(typeof error.codigo == 'string'){
                                    error.codigo = `${error.codigo} -`
                                }else{
                                    error.codigo = ''
                                }

                                toastAlert(`${error.codigo} ${error.descricao}`)

                                return false
                            });
                        }
                        
                        return response.json()
                    })
                    .then(function(response) {
                        if (!response) {
                            return false
                        }

                        return response.data[0]
                    })

                if(!aModelo){
                    return
                }

                this.modelo = aModelo
                this.carregandoModelo = false
            },
            /**
             * Função para exibir os dados do novo modelo
             */
            addNovoModelo(){
                this.indiceAtivo = -1
                this.novoModelo = true
                this.carregandoModelo = false
            },
            /**
             * Função para filtrar os modelos
             */
            filtrarModelos(aModelos){
                /**
                 * Verifica se existe informação no filtro
                 */
                if(this.filtro != ''){
                    /**
                     * Itera os modelo com o filter e verifica 
                     * se existe nome ou numero compativel
                     */
                    return aModelos.filter(oModelo => {
                        return oModelo.titulo.toLowerCase().includes(this.filtro.toLowerCase())
                    })
                }

                /**
                 * Retorna os modelos caso não for realizado o filtro
                 */
                return aModelos
            }
        }
    }
</script>

<style>
    html{
        overflow: hidden;
    }

    .title:not(:last-child), .subtitle:not(:last-child){
        margin-bottom: 0.5em;
    }

    .inp-btn{
        color: #bbb
    }

    .janela-principal{
        height: 100vh;
        overflow: hidden;
    }

    .modelos{
        height: 89vh;
        overflow-y: auto;
    }

    .barra-superior{
        margin: 0;
        height: 50px;
        background: #EDEDED;
        border-right: 1px solid #E1E1E1;
        border-bottom: 1px solid rgb(200, 200, 200);
    }

    .barra-superior .gg-search{
        position: absolute;
        margin: 9px 27px;
        transform: scale(var(--ggs,0.6));
    }

    .barra-superior input{
        width: 100%;
        border: none;
        margin: 0;
        height: 100%;
        outline: none;
        padding-left: 50px;
        color: gray;
    }

    .barra-superior-filtro{
        height: 35px;
    }

    .barra-superior button{
        margin: 0.5rem 2rem;
        font-size: 0.8rem;
    }

    .barra-superior div{
        margin-left: 10px;
    }

    .barra-superior span{
        line-height: 50px;
        margin-left: 25px;
        font-weight: 500;
    }

    .lista-de-conversas{
        padding: 0;
        background:white;
    }

    .conversa-ativa{
        padding: 0;
        background:#E5DDD5;
        position: relative;
    }

    .modelo{
        height: 100vh;
        margin: -50px 0;
        overflow-y: auto;
        display: grid;
    }

    .columns:last-child{
        margin: 0 -8px;
    }

    .section{
        padding: unset;
    }

    .container{
        max-width: unset;
    }
</style>
