<template>
    <div class="control">
        <label class="label">{{ label }}</label>
        <textarea 
            class="textarea" 
            :rows="rows"
            :class="{ 'is-danger': !preenchido && preenchidoInput }"
            @keyup="atualizarPreenchimento($event.target.value)"
            @input="$emit('input', $event.target.value)"
        >
            {{ value }}
        </textarea>
        <p class="help is-danger" v-if="!preenchido && preenchidoInput">Campo obrigatório</p>
    </div>
</template>

<script>
export default {
    name: "Textarea",
    props: ['label', 'rows', 'value', 'preenchido'],
    data() {
        return {
            preenchidoInput: typeof this.preenchido !== 'undefined' ? true : false
        }
    },
    methods: {
        atualizarPreenchimento(sValor) {
            if(sValor == ''){
                this.preenchidoInput = true
            }else{
                this.preenchidoInput = false
            }
        }
    }
}
</script>